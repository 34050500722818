const THREE = require("three");

function shuffle(sourceArray) {
  for (var i = 0; i < sourceArray.length - 1; i++) {
    var j = i + Math.floor(Math.random() * (sourceArray.length - i));
    var temp = sourceArray[j];
    sourceArray[j] = sourceArray[i];
    sourceArray[i] = temp;
  }
  return sourceArray;
}

export default class Waves {

  constructor(options) {
    this.rAFid = null;
    this.webgl = null;
    this.scene = new THREE.Scene();
    this.renderer = new THREE.WebGLRenderer({ alpha: true});
    this.light = new THREE.AmbientLight(0xffffff);
    this.camera;
    this.plane, this.numberOfVerticies;
    this.currentWaveHeight = 50;
    this.rowSize = 100;
    this.maxHeight = 10;
    this.t = 0.0;
    this.loader;
    this.tex;
    this.images = [
      '/data/specimens/specimen-work-sans-extra-bold.jpg',
      // 'https://s3.eu-central-1.amazonaws.com/rgbcloud/of/damien-poulain_bagnard-regular_of-specimen-flag.jpg',
      // 'https://s3.eu-central-1.amazonaws.com/rgbcloud/of/folkert-gorter_eb-garamond_of-specimen-flag.jpg',
      // 'https://s3.eu-central-1.amazonaws.com/rgbcloud/of/hort_junicode_of-specimen-flag.jpg',
      // 'https://s3.eu-central-1.amazonaws.com/rgbcloud/of/jon-bland_bluu-next_of-specimen-flag.jpg',
      // 'https://s3.eu-central-1.amazonaws.com/rgbcloud/of/marc-kremers_tex-gyre-heros-condensed-regular_of-specimen-flag.jpg',
      // 'https://s3.eu-central-1.amazonaws.com/rgbcloud/of/michael-seibert_gapsans_of-specimen-flag.jpg',
      // 'https://s3.eu-central-1.amazonaws.com/rgbcloud/of/orlando-loyd_liberation-sans_of-specimen-flag.jpg',
      // 'https://s3.eu-central-1.amazonaws.com/rgbcloud/of/tom-darracott_archivo-black_of-specimen-flag.jpg',
      // 'https://s3.eu-central-1.amazonaws.com/rgbcloud/of/wolfgang-schieffel_aileron-black_of-specimen-flag.jpg',
    ];
    window.addEventListener("resize", this.onWindowResize.bind(this), false);
  }


  init() {
    this.webgl = document.getElementById("webgl");
    this.renderer.setClearColor(0x000000, 0);
    this.renderer.setSize(this.webgl.clientWidth, this.webgl.clientHeight);
    this.webgl.appendChild(this.renderer.domElement);
    this.scene.add(this.light);
    this.camera = new THREE.PerspectiveCamera(
      32,
      this.webgl.clientWidth,
      this.webgl.clientHeight,
      1,
      1000
    );

    this.camera.position.x = -160;
    this.camera.position.z = 180;
    this.camera.position.y = -50;

    this.camera.rotation.x = this.getRadian(15);
    this.camera.rotation.z = this.getRadian(15);
    this.camera.rotation.y = this.getRadian(-40);

    this.loader = new THREE.TextureLoader();
    this.loader.crossOrigin = "anonymous";

    this.tex = this.loader.load( this.images[0] );

    var material = new THREE.MeshBasicMaterial({
      map: this.tex
      // color: 0x0000FF,
      // wireframe: true
    });

    this.plane = new THREE.Mesh(
      new THREE.PlaneGeometry(200, 300, this.rowSize, this.rowSize),
      material
    );

    // setInterval(() => {
    //   this.loader.load( shuffle(this.images)[0], ( tex ) => {
    //     this.tex = tex;
    //     this.plane.material.map = this.tex;
    //     this.plane.material.needsUpdate = true;
    //   });
    // }, 3000 );

    this.plane.rotation.y = this.getRadian(-75);
    this.plane.rotation.z = this.getRadian(-10);
    this.plane.position.y = 0;

    this.numberOfVerticies = this.plane.geometry.vertices.length;

    this.initPlane();

    this.scene.add(this.plane);

    this.rAFid = window.requestAnimationFrame(this.render.bind(this));
    this.onWindowResize();


  }

  destory() {
    window.removeEventListener("resize", this.onWindowResize);
    window.cancelAnimationFrame(this.rAFid);
  }

  initPlane() {
    for (var j = 0; j < this.rowSize + 1; j++) {
      for (var i = 0; i < this.rowSize + 1; i++) {
        this.plane.geometry.vertices[(this.rowSize + 1) * j + i].z =
          this.maxHeight * Math.sin((j / this.rowSize) * 3);
      }
    }
  }

  render() {
    for (var j = 0; j < this.rowSize + 1; j++) {
      for (var i = 0; i < this.rowSize + 1; i++) {
        this.plane.geometry.vertices[(this.rowSize + 1) * j + i].z =
          this.maxHeight * Math.sin((j / this.rowSize) * 3 + this.t * 0.1);
        this.plane.geometry.vertices[(this.rowSize + 1) * j + i].z +=
          (this.maxHeight / 5) *
          Math.sin((j / this.rowSize) * 12 + this.t * 0.08);
        this.plane.geometry.vertices[(this.rowSize + 1) * j + i].z +=
          (this.maxHeight / 13) *
          Math.cos((j / this.rowSize) * 18 + this.t * 0.4);
        this.plane.geometry.vertices[(this.rowSize + 1) * j + i].z +=
          this.maxHeight * Math.cos((i / this.rowSize) * 3 + this.t * 0.1);
        this.plane.geometry.vertices[(this.rowSize + 1) * j + i].z +=
          (this.maxHeight / 5) *
          Math.cos((i / this.rowSize) * 12 + this.t * 0.08);
        this.plane.geometry.vertices[(this.rowSize + 1) * j + i].z +=
          (this.maxHeight / 13) *
          Math.sin((i / this.rowSize) * 18 + this.t * 0.4);
      }
    }

    this.t += 0.55;

    this.plane.geometry.verticesNeedUpdate = true;

    this.renderer.render(this.scene, this.camera);

    this.rAFid = window.requestAnimationFrame(this.render.bind(this));
  }

  onWindowResize() {
    this.camera.aspect = this.webgl.clientWidth / this.webgl.clientHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(this.webgl.clientWidth, this.webgl.clientHeight);
  }

  getRadian(x) {
    return (Math.PI * x) / 180;
  }
}
