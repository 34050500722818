import React, { Component, Link } from 'react';
import $ from 'jquery';
import StorePreviewContainer from 'components/store-preview-container/store-preview-container.js';
import shopifyClient from 'shopify-buy';

export default class StorePage extends Component {

    constructor() {
        super();
        this.client;
        this.state = {
            items: null,
        };
    }

    componentDidMount() {

        this.client = shopifyClient.buildClient({
            domain:                'store.open-foundry.com',
            storefrontAccessToken: '71c31dde6cc55e4ab1d59f4861fffb5a',
            appId:                 '94570348613'
        });

        this.client.collection.fetchAllWithProducts().then((collections) => {
            this.products = collections[0].products.map((product, i) => {
	              var _id = atob(product.id);
	              var _gid = _id.split("/");
	              var id = _gid[_gid.length - 1];
	              if (  product.images ) {
	                var image = product.images.map((img, key) => {
	                    return img.src;
	                });
	              }
	              return {
                    id: id,
                    images: image,
                    hex: product.description
	              }
            });

            const items = this.products.map((p, i) => {
                return <StorePreviewContainer key={p.id} id={p.id} backgroundColor={p.hex} images={p.images} />
            })

            this.setState({ items: items })

        });

    }

    componentWillUnmount() {}

    render() {
        if (this.state.items === null) {
            return(
                <div></div>
            )
        } else {
            return (
                <div>
                    {this.state.items}
                </div>
            )
        }
    }
}