import { Router, IndexRoute, Route, IndexLink, IndexRedirect, Link, browserHistory } from 'react-router'
import React, { Component } from 'react';
import Helmet from "react-helmet";
import { render } from 'react-dom';
import classNames from 'classnames';
import FontSpecimen from './components/font-specimen/font-specimen.js';
import FontList from './components/font-list/font-list.js';
import Debug from './components/debug/debug.js';
import HomePage from './components/home/home.js';
import StorePage from './components/store/store.js';
import InfoPage from './components/info/info.js';
import TermsPage from './components/terms/terms.js';
import PrivacyPage from './components/privacy/privacy.js';
import $ from 'jquery';
import Tabletop from 'tabletop';
import ReactTransitionGroup from 'react-addons-transition-group';
import { getFontId, getFullFontName } from 'util/content_util.js';
import { Dispatcher } from 'flux';
import appDispatcher from 'app-dispatcher';
import appModel from 'app-model';
import Submission from 'components/submission/submission.js';

var cache = {
  fonts: null
};

class App extends Component {

  constructor() {
    super()
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.handleAppEvent = this.handleAppEvent.bind(this);

    this.isScrolled = false;
    this.delta = 100;
    this.lastScrollTop = 100;

    this.state = {
      isLoaded: false,
      isMenuOpen: false,
      isLogoUp: false,
      fonts: []
    };

    // Shopify
    this.shopifyClient = window.ShopifyBuy.buildClient({
      domain: 'store.open-foundry.com',
      apiKey: '71c31dde6cc55e4ab1d59f4861fffb5a',
      appId: '94570348613'
    });

    window.shopifyUI = window.ShopifyBuy.UI.init(this.shopifyClient);

  }

  componentDidMount() {

    var self = this;
    var navbarHeight = 50;

    $(window).on('scroll', function () {
      self.isScrolled = true;
      requestAnimationFrame(self.checkScroll.bind(self))
    });

    this.forceUpdateMenu = true;
    requestAnimationFrame(self.checkScroll.bind(self))

    appDispatcher.register(this.handleAppEvent);

    appDispatcher.dispatch({
      actionType: 'fetch-font-data'
    });

    // Shopify --> create cart
    window.shopifyUI.createCart({
      moneyFormat: '£{{amount_no_decimals_with_comma_separator}}',
      toggles: [{
        node: this.refs.cartToggle
      }],
      options: {
        cart: {
          iframe: true,
          events: {
            afterRender: function( cart ) {
              if ( cart.isEmpty ) {
                cart.toggles[0].node.classList.remove('is-active');
              } else {
                cart.toggles[0].node.classList.add('is-active');
              }
            }
          },
          text: {
            empty: 'Your cart is empty 👀',
            button: 'CHECKOUT',
            total: 'Subtotal',
            notice: '*All prices including VAT, excl. Shipping'
          },
          styles: {
            cart: {
              'box-shadow': '-1px 0 1px rgba(0,0,0,0.1)'
            },
            title: {
              'font-weight': '600',
              'line-height': '1.6',
              'color': '#6c1aff',
              'font-size': '16px',
            },
            total: {
              'font-weight': '600',
              'line-height': '1.6',
              'color': 'black',
            },
            subtotal: {
              'font-weight': '600',
              'line-height': '1.6',
              'color': 'black',
              'font-size': '16px',
            },
            subtotalText: {
              'font-weight': '600',
              'line-height': '1.6',
              'color': 'black',
              'font-size': '16px',
              'text-transform': 'capitalize',
            },
            subtotalPrice: {
              'font-weight': '600',
              'line-height': '1.6',
              'color': 'black',
              'font-size': '16px',
            },
            close: {
              'color': '#cccccc',
              'outline': 'none',
              'transform': 'scale(.7)',
              ':hover': {
                'transform': 'scale(.7)',
                'color': '#000000',
              },
              ':active': {
                'transform': 'scale(.65)',
              },
              'top': '1px',
              'right': '6px',
            },
            button: {
              'background-color': 'black',
              'color': 'white',
              'outline' : 'none',
              'font-weight': '700',
              'letter-spacing': '0',
              'border-radius': '0px',
              'transition': 'transform .15s ease',
              ':hover': {
                'background-color': '#6c1aff',
              },
              ':active': {
                'transform': 'scale(.99)',
              }
            },
            footer: {
              'font-weight': '600',
              'color': 'black',
              'font-size': '16px',
              'text-align': 'left',
            },
            header: {
              'color': 'black',
              'padding': '12px 20px',
            },
            lineItems: {
              'color': 'black',
              'width': '100%',
              'font-weight': '600',
              'line-height': '1.3',
              'margin-bottom': '30px'
            },
            notice: {
              'color': '#cccccc',
              'font-size': '13px',
              'text-align': 'left',
              'font-weight': '600',
            },
            currency: {
              'color': 'red',
            },
            emptyCart: {
              'color': 'black',
              'font-weight': '600',
              'font-size': '16px',
              'display': 'flex',
              'justify-content': 'center',
              'align-items': 'center',
              'height': '100%',
            },
            lineItem: {
              'font-size': '18px'
            },
            variantTitle: {
              'color': 'red'
            },
          }
        },
        toggle: {
          iframe: false,
          contents: {
            count: true,
            icon: false,
            title: true
          },
          text: {
            title: 'Cart',
          },
        }
      }
    });

  }

  componentWillUnmount() {

    $(window).off('scroll');
  }

  handleAppEvent(e) {

    switch (e.actionType) {

      case 'hide-menu':
        this.setState({
          isNavHidden: true
        });
        break;

      case 'font-data-updated':
        this.setState({
          isLoaded: true,
          fonts: e.data
        });
        break;
    }
  }

  handleMenuClick() {
    let { isMenuOpen } = this.state;
    if (isMenuOpen) {
      this.setState({
        isMenuOpen: false,
        isLogoUp: true
      });
    }
  }

  checkScroll() {

    var scrollTop = $(window).scrollTop();
    var windowHeight = $(window).height();
    var documentHeight = $(document).height();
    var navbarHeight = 50;

    // scroll more than delta
    if (Math.abs(this.lastScrollTop - scrollTop) <= 100 && !this.forceUpdateMenu) return;

    this.forceUpdateMenu = false;
    this. lastScrollTop = scrollTop;
  }

  render() {

    let iconClassName = this.state.isMenuOpen ? 'menu-icon active' : 'menu-icon';
    let listClassName = this.state.isMenuOpen ? 'menu-list open' : 'menu-list';
    let signupClassName = this.state.isMenuOpen ? 'menu-signup open' : 'menu-signup';
    let logoClassName = this.state.isMenuOpen ? 'menu-logo open' : 'menu-logo';

    let rootClassName = classNames({
      'is-loaded': this.state.isLoaded,
      'nav-hidden': this.state.isNavHidden
    });

    if (this.state.isLogoUp) {
      logoClassName += ' up';
    }

    let { location } = this.props;

    let pathName = location.pathname;

    return (
      <div className={rootClassName}>
        <header className='of-navbar'>
          <nav>
            <ul className="menu-header">
              <li className={logoClassName}>
                <Link to="/">
                  <span className="open">
                    <svg id="open" x="0px" y="0px" viewBox="719 269.5 54 28" enable-background="new 719 269.5 54 28">
                      <g>
                        <rect x="720" y="270.5" width="52" height="26"/>
                        <path d="M731.6,277c3.5,0,5.6,2.6,5.6,6c0,3.3-2.1,5.9-5.6,5.9s-5.6-2.6-5.6-5.9C726,279.7,728.1,277,731.6,277z M731.6,286.9 c2.3,0,3.1-1.9,3.1-3.8c0-2-0.8-3.9-3.1-3.9s-3.1,1.9-3.1,3.9C728.5,285,729.3,286.9,731.6,286.9z M738.8,280.5h2.2v1.1h0 c0.5-0.9,1.4-1.3,2.5-1.3c2.6,0,3.8,2.1,3.8,4.4c0,2.2-1.2,4.3-3.6,4.3c-1,0-1.9-0.4-2.5-1.2h0v3.9h-2.3V280.5z M745,284.6 c0-1.3-0.5-2.7-2-2.7c-1.5,0-2,1.3-2,2.7c0,1.3,0.5,2.6,2,2.6C744.5,287.2,745,286,745,284.6z M750.6,285.1c0.1,1.4,0.8,2.1,2,2.1 c0.9,0,1.6-0.6,1.8-1.1h2c-0.6,2-2,2.8-3.9,2.8c-2.6,0-4.2-1.8-4.2-4.4c0-2.5,1.7-4.4,4.2-4.4c2.8,0,4.2,2.4,4,4.9H750.6z M754.3,283.7c-0.2-1.2-0.7-1.8-1.8-1.8c-1.4,0-1.9,1.1-1.9,1.8H754.3z M758,280.5h2.2v1.2h0c0.6-0.9,1.6-1.4,2.5-1.4 c2.4,0,3,1.4,3,3.4v5.1h-2.3v-4.7c0-1.4-0.4-2-1.5-2c-1.2,0-1.8,0.7-1.8,2.4v4.3H758V280.5z"/>
                      </g>
                    </svg>
                  </span>
                  <span className="foundry">
                    <svg id="foundry" x="0px" y="0px" viewBox="783.2 269.5 76 28" enable-background="new 783.2 269.5 76 28">
                      <g>
                        <rect x="784.2" y="270.5" width="74" height="26"/>
                        <path d="M790.7,277.3h8v2.1h-5.5v2.6h4.8v2h-4.8v4.7h-2.5V277.3z M803.7,280.2c2.6,0,4.3,1.7,4.3,4.4c0,2.6-1.7,4.4-4.3,4.4 c-2.6,0-4.3-1.7-4.3-4.4C799.4,282,801.1,280.2,803.7,280.2z M803.7,287.2c1.6,0,2-1.3,2-2.6c0-1.3-0.5-2.7-2-2.7 c-1.5,0-2,1.3-2,2.7C801.7,285.9,802.1,287.2,803.7,287.2z M817.2,288.7H815v-1.2h0c-0.6,0.9-1.6,1.4-2.5,1.4c-2.4,0-3-1.4-3-3.4 v-5.1h2.3v4.7c0,1.4,0.4,2,1.5,2c1.2,0,1.8-0.7,1.8-2.4v-4.3h2.3V288.7z M819.1,280.5h2.2v1.2h0c0.6-0.9,1.6-1.4,2.5-1.4 c2.4,0,3,1.4,3,3.4v5.1h-2.3v-4.7c0-1.4-0.4-2-1.5-2c-1.2,0-1.8,0.7-1.8,2.4v4.3h-2.3V280.5z M834.6,287.7L834.6,287.7 c-0.6,0.9-1.5,1.3-2.5,1.3c-2.5,0-3.7-2.1-3.7-4.4c0-2.2,1.2-4.3,3.7-4.3c1,0,1.9,0.4,2.4,1.2h0v-4.2h2.3v11.4h-2.2V287.7z  M832.6,281.9c-1.5,0-2,1.3-2,2.6c0,1.3,0.6,2.7,2,2.7c1.5,0,2-1.3,2-2.7C834.5,283.2,834,281.9,832.6,281.9z M838.6,280.5h2.2v1.5 h0c0.4-1,1.5-1.8,2.6-1.8c0.2,0,0.4,0,0.5,0.1v2.1c-0.2,0-0.5-0.1-0.8-0.1c-1.7,0-2.2,1.2-2.2,2.7v3.7h-2.3V280.5z M849.5,289.8 c-0.5,1.3-1.3,1.9-2.8,1.9c-0.5,0-0.9,0-1.4-0.1v-1.9c0.4,0,0.9,0.1,1.3,0.1c0.8-0.1,1-0.9,0.8-1.6l-2.9-7.8h2.4l1.9,5.7h0l1.8-5.7 h2.4L849.5,289.8z"/>
                      </g>
                    </svg>
                  </span>
                  <span className="of">
                    <svg id="of" y="0px" y="0px" viewBox="0 0 34 28">
                      <g>
                        <rect x="1" y="1" width="32" height="26"/>
                        <path d="M6.91,11.68A5.75,5.75,0,0,1,8,9.77,5,5,0,0,1,9.79,8.49,5.71,5.71,0,0,1,12.15,8a5.71,5.71,0,0,1,2.36.46,4.94,4.94,0,0,1,1.76,1.28,5.75,5.75,0,0,1,1.11,1.91,7.29,7.29,0,0,1,.38,2.38,7,7,0,0,1-.38,2.32,5.61,5.61,0,0,1-1.11,1.88,5,5,0,0,1-1.76,1.26,5.88,5.88,0,0,1-2.36.45,5.88,5.88,0,0,1-2.36-.45A5.05,5.05,0,0,1,8,18.26a5.61,5.61,0,0,1-1.11-1.88,7,7,0,0,1-.38-2.32A7.29,7.29,0,0,1,6.91,11.68Zm2.3,3.78a3.64,3.64,0,0,0,.54,1.22,2.69,2.69,0,0,0,1,.87,2.89,2.89,0,0,0,1.44.33,2.88,2.88,0,0,0,1.43-.33,2.69,2.69,0,0,0,1-.87,3.64,3.64,0,0,0,.54-1.22,6,6,0,0,0,.17-1.4,6.34,6.34,0,0,0-.17-1.46,3.76,3.76,0,0,0-.54-1.26,2.71,2.71,0,0,0-1-.88,3,3,0,0,0-1.43-.32,3,3,0,0,0-1.44.32,2.71,2.71,0,0,0-1,.88,3.76,3.76,0,0,0-.54,1.26A6.34,6.34,0,0,0,9,14.06,6,6,0,0,0,9.21,15.46ZM27.61,8.3v2.11H22.1v2.64h4.78V15H22.1v4.72H19.58V8.3Z"></path>
                      </g>
                    </svg>
                  </span>
                </Link>
              </li>
            </ul>
            <ul className={listClassName}>
              <li><Link onClick={ this.handleMenuClick } to="/fonts" activeClassName="active">Fonts</Link></li>
              <li><Link onClick={ this.handleMenuClick } to="/info" activeClassName="active">Info</Link></li>
            </ul>
          </nav>
        </header>
        <div className="of-main">
          {this.props.children}
        </div>
        <Submission/>
        <footer className="of-footer">
           <ul className="of-footer-nav">
              <li><Link onClick={ this.handleMenuClick } to="/" activeClassName="active of-footer-logo">OF v1.1.8</Link></li>
              <li><Link onClick={ this.handleMenuClick } to="/fonts" activeClassName="active">Fonts</Link></li>
              <li><Link onClick={ this.handleMenuClick } to="/info" activeClassName="active">Info</Link></li>
           </ul>
           <ul className="of-footer-nav">
              {/* <li><Link onClick={ this.handleMenuClick } to="/terms#shipping" activeClassName="active">Shipping</Link></li> */}
              <li><Link onClick={ this.handleMenuClick } to="/terms" activeClassName="active">Terms</Link></li>
              <li><Link onClick={ this.handleMenuClick } to="/privacy" activeClassName="active">Privacy</Link></li>
              <li>
                <a href="https://www.instagram.com/openfoundry/">
                  <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1">
                      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                          <g id="footer" transform="translate(-1407.000000, -14.000000)">
                              <g id="Stacked-Group" transform="translate(1194.000000, 14.000000)">
                                  <g id="util/icon/insta/black" transform="translate(213.000000, 0.000000)">
                                      <g id="Insta">
                                          <circle id="Oval-2-Copy" fill="#1C1C1C" cx="14" cy="4" r="1"></circle>
                                          <circle id="Oval-2" stroke="#1C1C1C" strokeWidth="1.5" cx="9" cy="9" r="4"></circle>
                                          <rect id="Rectangle" stroke="#1C1C1C" strokeWidth="1.5" x="0.75" y="0.75" width="16.5" height="16.5" rx="5"></rect>
                                      </g>
                                  </g>
                              </g>
                          </g>
                      </g>
                  </svg>
                </a>
              </li>
           </ul>
        </footer>
      </div>
    )
  }
}

App.contextTypes = {
  location: React.PropTypes.object
};

class Fonts extends Component {

  constructor() {
    super();

    this.handleAppEvent = this.handleAppEvent.bind(this);

    this.state = {
      isSpecimen: false,
      fonts: []
    };
  }

  handleAppEvent(e) {
    switch (e.actionType) {
      case 'font-data-updated':
        this.setState({
          fonts: e.data
        });
        break;
    }
  }

  componentDidMount() {
    const self = this;

    this.handleAppEventListener = appDispatcher.register(this.handleAppEvent);
    appDispatcher.dispatch({ actionType: 'fetch-font-data' });

  }

  componentWillUnmount() {
    appDispatcher.unregister(this.handleAppEventListener);
  }

  componentDidUpdate() {
    let { location } = this.props;
    let { isSpecimen} = this.state;

    let pathName = location.pathname;

    if (isSpecimen) {
      if (pathName === '/fonts') this.setState({ isSpecimen: false });
    } else {
      if (pathName !== '/fonts') this.setState({ isSpecimen: true });
    }
  }

  render() {
    const { fonts, isSpecimen } = this.state;

    return (
      <div>
        <Helmet title={"Open Foundry / Fonts"} />
        <FontList fixed={isSpecimen} fonts={fonts} />
        {this.props.children}
      </div>
    )
  }
}

Fonts.contextTypes = {
  location: React.PropTypes.object
};

class Specimen extends Component {

  constructor() {
    super();

    this.onComplete = this.onComplete.bind(this);
    this.navigateToOpen = this.navigateToOpen.bind(this);
    this.handleAppEvent = this.handleAppEvent.bind(this);

    this.state = {
      fonts: []
    };
  }

  navigateToOpen() {
    this.context.router.push('/fonts');
  }

  onComplete() {
    this.navigateToOpen();
  }

  handleAppEvent(e) {
    switch (e.actionType) {
      case 'font-data-updated':
        this.setState({
          fonts: e.data
        });
        break;
    }
  }

  componentDidMount() {
    this.handleAppEventListener = appDispatcher.register(this.handleAppEvent);
    appDispatcher.dispatch({ actionType: 'fetch-font-data' });
  }

  componentWillUnmount() {
    appDispatcher.unregister(this.handleAppEventListener);
  }

  render() {
    let { fontId } = this.props.params;

    if (!this.state.fonts) return <div>Loading...</div>

    let matches = this.state.fonts.filter(function (font) {
      return getFontId(font) === fontId;
    });

    let match = matches.length ? matches[0] : null;

    return  <ReactTransitionGroup>
            <Helmet title={"Open Foundry / Fonts / " + getFullFontName(match)} />
            <FontSpecimen
               key={fontId}
               fontId={fontId}
               font={match}
               onCompleteScroll={this.onComplete} />
            </ReactTransitionGroup>
  }
}

Specimen.contextTypes = {
  router: React.PropTypes.object.isRequired
}


class Home extends Component {
  render() {
    return <div>
              <Helmet title={"Open Foundry"} />
              <HomePage />
           </div>
  }
}

class Store extends Component {
  render() {
    return <div>
              <Helmet title={"Open Foundry / Store"} />
              <StorePage />
           </div>
  }
}

class Info extends Component {
  render() {
    return <div>
              <Helmet title={"Open Foundry / Info"} />
              <InfoPage />
           </div>
  }
}

class Terms extends Component {
  render() {
    return <div>
              <Helmet title={"Open Foundry / Terms"} />
              <TermsPage />
           </div>
  }
}

class Privacy extends Component {
  render() {
    return <div>
              <Helmet title={"Open Foundry / Privacy"} />
              <PrivacyPage />
           </div>
  }
}


browserHistory.listen(function (location) {

  window.scrollTo(0, 0);

  // need to render <Helmet> before retrieving pages title
  setTimeout(function () {

    window.ga('send', 'pageview', location.pathname);

    if (location.pathname === '/signup') {
      appDispatcher.dispatch({ actionType: 'hide-menu' })
    }

  }, 50);
});

function hashLinkScroll() {
  const { hash } = window.location;
  if (hash !== '') {
    setTimeout(() => {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) element.scrollIntoView();
    }, 0);
  }
}

render((
  <Router history={browserHistory} onUpdate={hashLinkScroll}>
    <Route path="/" component={App}>
      <IndexRoute component={Home}/>
      <Route path="fonts" component={Fonts}>
        <Route path=":fontId" component={Specimen} />
      </Route>
      {/* <Route path="store" component={Store} /> */}
      <Route path="info" component={Info} />
      <Route path="terms" component={Terms} />
      <Route path="privacy" component={Privacy} />
      <Route path="debug" component={Debug} />
    </Route>
  </Router>
),
document.querySelector('.of-container')
);
