import React, { Component } from "react";
import { ChromePicker } from "react-color";

class FontColourPicker extends Component {
  constructor() {
    super();
    this.state = {
      value: "",
      displayColorPicker: false
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleClick(value) {
    this.setState({
      displayColorPicker: !this.state.displayColorPicker
    });
  }

  handleClose(value) {
    this.setState({
      displayColorPicker: false
    });
  }

  handleChange(value) {
    let hex = value.hex,
      { onUpdate } = this.props;

    this.setState({
      value: hex
    });

    onUpdate && onUpdate(hex);
  }

  render() {
    const popover = {
      position: "absolute",
      zIndex: "2",
      transform: "translateX(-90%)"
    };
    const cover = {
      position: "absolute",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px"
    };

    return (
      <div
        className="of-font-colour-picker-container"
        tabIndex="0"
        onBlur={this.handleClose}
      >
        <button className="color-wheel" onClick={this.handleClick}>
          {" "}
        </button>{" "}
        {this.state.displayColorPicker ? (
          <div style={popover}>
            <div style={cover} onClick={this.handleClose} />{" "}
            <ChromePicker
              disableAlpha={true}
              color={this.state.value}
              onChange={this.handleChange}
            />{" "}
          </div>
        ) : null}{" "}
      </div>
    );
  }
}

export default FontColourPicker;