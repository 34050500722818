import React, { Component, Link } from 'react';
import $ from 'jquery';

export default class InfoPage extends Component {
  render() {
    return (
      <div className="page--info">
        <section>
          <div className="of-grid-container">
            <div className="of-row">
              <div className="col-12">
                  <p>Open Foundry is a <i>FREE</i> platform for curated open-source typefaces; to highlight their beauty, activate ideas and encourage exploration.</p>
                  <p>Since the launch of OF, we’ve had such inspiring feedback and received many innovative open-source typefaces from around the world. We are NOW ready to reimagine how
                    these fonts are found, seen, used, manipulated, hacked and created from scratch. For that very reason, we are ready to create the next generation from the the ground up!</p>
                  <p>Open Foundry 2.0 will harness modern web technologies, data integrations, and social oriented features to empower true collaboration, sharing and knowledge. <i>[We’ll keep you posted]</i>.</p>
                  <p>OF Store — Our inspiration comes from you and we’d like the next generation of font-hunters and type-lovers to be equipped with new tools for the alphabeticalchallenges of the future. To support our mission for v2.0, look out for Limited Edition OF Merchandise of all forms, shapes and sizes.</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}