import React, { Component } from 'react';
import FontBackgroundToggle from '../../components/font-background-toggle/font-background-toggle.js';
import FontUppercase from '../../components/font-uppercase/font-uppercase.js';
import FontColourPicker from '../../components/font-colour-picker/font-colour-picker.js';

export default class FontColours extends Component {
  render() {
    return (
      <div className="of-font-colour">
        <div className="of-font-background-toggle-container">
          <FontBackgroundToggle background={this.props.background} onUpdateColour={this.props.onUpdate} onUpdate={this.props.onUpdateBackground} />
        </div>      
        <div className="of-font-uppercase-toggle-container">
          <FontUppercase value={this.props.uppercase} background={this.props.background} onUpdate={this.props.onUpdateTextTransform} />
        </div>
        <FontColourPicker value={this.props.color} onUpdate={this.props.onUpdate} />
      </div>
    )
  }
}