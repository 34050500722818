import $ from 'jquery';
import { Dispatcher } from 'flux';
import { getFontId } from './util/content_util.js';
import appDispatcher from 'app-dispatcher';

var cache = {
  fonts: null
}

class AppModel {

  constructor() {
    this.handleAppEvent = this.handleAppEvent.bind(this);
    appDispatcher.register(this.handleAppEvent);
  }

  handleAppEvent(e) {
    switch (e.actionType) {
      case 'fetch-font-data':
        if (this.isSiteDataAvailable) {
          setTimeout(function () {
            appDispatcher.dispatch({
              actionType: 'font-data-updated',
              data: cache.fonts
            });

          });
        } else {
          if (!this.fontsLoading) {
            this.fetchFontData();
          }
        }
        break;
    }
  }

  fetchFontData() {

    if (this.fontsLoading || cache.fonts) {
      console.log('fonts already loaded');
      // fetching in progress: skip
      return;
    }

    this.fontsLoading = true;

    $.get('../../data/sheet.json')
      .done(function (res) {
        cache.fonts = cache.fonts || res;
        this.decorateFonts(cache.fonts);
      }.bind(this));

  }


  decorateFonts(fonts) {

    fonts.forEach(function (font) {
      // we're using the font object as a model to be reflected
      // by any views referencing it (i.e. list & specimen).
      // This could be done better by implementing Flux entirely,
      // however this seems to work fine for now.
      font.dispatcher = new Dispatcher

      font.__key = getFontId(font);
    })

    this.isSiteDataAvailable = true;
    this.parsedFonts = fonts;

    appDispatcher.dispatch({
      actionType: 'font-data-updated',
      data: this.parsedFonts
    });

  }
}

var appModel = new AppModel;

export default appModel;