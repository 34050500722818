import { Link } from 'react-router';
import React, { Component } from 'react';

import $ from 'jquery';

import Waves from 'components/webgl/waves.js';
import Newsletter from 'components/newsletter/newsletter.js';
// import Instabutton from 'components/instabutton/instabutton.js';

export default class HomePage extends Component {

  constructor() {
    super()
    this.state = {}
    this.waves = new Waves();
  }

  componentDidMount() {
    this.waves.init();
  }

  componentWillUnmount() {
    this.waves.destory();
  }

  render() {
    return (
      <div className="page--home">
        <section className="of-home-preview of-home-preview--fonts is-dark">
          <div className="of-home-preview-content">
              <Link to="/fonts" className="of-home-preview-content-subtitle">Fonts</Link>
              <p className="of-home-preview-content-title">Curated and Open-Source</p>
              <Link to="/fonts" className="btn is-white">Explore Fonts</Link>
          </div>
          <div id="webgl"></div>
        </section>
        {/* <section className="of-home-preview of-home-preview--store is-dark is-shop">
          <div className="of-home-preview-content">
              <Link to="/store" className="of-home-preview-content-subtitle">Store <span className="subtitle-new">New</span></Link>
              <p className="of-home-preview-content-title">Official Apparel & Merch</p>
              <Link to="/store" className="btn is-white">Shop NOW</Link>
          </div>
          <div className="of-home-preview-background">
            <img src="/img/of-store_preview.png" />
          </div>
        </section> */}
        <Newsletter />
        <section className="of-home-preview of-home-preview--info is-dark is-auto-height">
          <div className="of-home-preview-content">
              <p className="h1">
                Open Foundry is a <i>FREE</i> platform for curated open-source typefaces; to highlight their beauty, activate ideas and encourage exploration. Open Foundry just launched its Store and is currently working on a <i>NEW</i> v2.0. <Link to="/info" className="btn is-white">Read more</Link>
              </p>
          </div>
        </section>
      </div>
    )
  }
}
