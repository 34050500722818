import React, { Component } from 'react';
import { Dispatcher } from 'flux';
import classNames from 'classnames';
import $ from 'jquery';
import shopifyClient from 'shopify-buy';
import FontBackgroundToggle from '../../components/font-background-toggle/font-background-toggle.js';
import Flickity from 'flickity';

export default class StorePreviewContainer extends Component {

  constructor() {
    super();
    this.isMount = false;
    this.shopifyClient = null;
    this.shopifyUI = null;
    this.flkty;

    this.state = {
      selectedIndex: 0
    }

    this.handleClick = this.handleClick.bind(this);

  }

  componentDidMount() {

    this.isMount = true;

    window.shopifyUI.createComponent('product', {
      id: this.props.id,
      node: this.refs.product,
      moneyFormat: '£{{amount_no_decimals_with_comma_separator}}',
      cartNode: document.querySelector('.menu-cart'),
      toggles: [{
        node: document.querySelector('.menu-cart'),
      }],
      options: {
        product: {
          iframe: false,
          width: 1024,
          text: {
            button: 'Add to Cart'
          },
          contents: {
            img: false,
          }
        }
      }
    });

    this.flkty = new Flickity(this.refs.gallery, {
      accessibility: true,
      adaptiveHeight: false,
      autoPlay: false,
      cellAlign: 'center',
      cellSelector: undefined,
      contain: true,
      draggable: '>1',
      dragThreshold: 3,
      freeScroll: false,
      groupCells: false,
      initialIndex: 0,
      lazyLoad: 2,
      percentPosition: true,
      prevNextButtons: false,
      pageDots: false,
      resize: true,
      rightToLeft: false,
      setGallerySize: false,
      watchCSS: false,
      wrapAround: false
    });

    this.flkty.on( 'select', ( index ) =>  {
      if ( this.state.selectedIndex != index ) {
        this.setState({selectedIndex: index});
      }
    });

  }

  componentWillUnmount() {
    this.isMount = false;
    window.shopifyUI.destroyComponent('product', this.props.id);
  }

  handleClick( i) {
    this.flkty.select(i);
  }

  render() {

    const { images, id } = this.props;

    const gallery = images.map( (imgsrc, i) => {
      return (
            <img data-flickity-lazyload={imgsrc} key={(i + id)}  />
        )
    });

    const thumbs = images.map( (imgsrc, i) => {
      return (
        <img src={imgsrc} key={(i + id)} width="100%" onClick={ () => {this.handleClick(i)} } className={classNames({'active': this.state.selectedIndex == i})}  />
      )
    });

    return (
      <div className="of-shop-preview" style={{background: this.props.backgroundColor}}>
        <div ref="product"></div>
        <div className="product-gallery" ref="gallery">
          { gallery }
        </div>
        <div className="product-thumbs">
          { thumbs }
        </div>
      </div>
    )
  }
}
